   $( "#colorspan" ).click(function() {
      $("#kleuren").css("display", "grid")
      $("#logo").css("display", "none")
      $("#thema").css("display", "none")
   })

   $( "#logospan" ).click(function() {
      $("#kleuren").css("display", "none")
      $("#logo").css("display", "grid")
      $("#thema").css("display", "none")
   })

   $( "#themespan" ).click(function() {
      $("#kleuren").css("display", "none")
      $("#logo").css("display", "none")
      $("#thema").css("display", "grid")
   })


   const navigation_items_elms = document.querySelectorAll(
      ".navigation-bar .list-items .item"
    );
   const navigation_pointer = document.querySelector(".navigation-bar .pointer");
    
    navigation_items_elms.forEach((item, index) => {
      item.addEventListener("click", (e) => {
        e.preventDefault();
        navigation_items_elms.forEach((itm) => itm.classList.remove("active"));
        item.classList.add("active");
    
        const parentWidth = item.parentElement.clientWidth;
        const lefPercent = (parentWidth / navigation_items_elms.length) * index;
        navigation_pointer.style.left = lefPercent + "px";
      });
    });
    
      const iconw = document.querySelector(".iconw")
      const icong = document.querySelector(".icong")
      const iconr = document.querySelector(".iconr")
      
    $(function() {

      $(".menu-link").click(function(e) {
        e.preventDefault();
        
        $(".menu-overlay").toggleClass("open");
        $(".menu").toggleClass("open");
  
        $(".menu-circle-opened").toggleClass("true");
      });

      $(".menu-circle-settings").click(function(e){
        e.preventDefault();
        $(".menu-overlay").toggleClass("open");
        $(".menu").toggleClass("open");        
      
        $(".menu-circle-opened").toggleClass("true");
      })
       
      $(".closeMenu").click(function(e){
        e.preventDefault();
        $(".menu-overlay").toggleClass("open");
        $(".menu").toggleClass("open");        
      
        $(".menu-circle-opened").toggleClass("true");
      })

    });

      iconw.style.display = "block"

    $("#color-w").click(function() {
      iconw.style.display = "block"
      icong.style.display = "none"
      iconr.style.display = "none"
    
    })

    $("#color-g").click(function() {
      iconw.style.display = "none"
      icong.style.display = "block"
      iconr.style.display = "none"
    })

    $("#color-r").click(function() {
      iconw.style.display = "none"
      icong.style.display = "none"
      iconr.style.display = "block"
    })

 

  var popup = document.querySelector(".resetClicked");
  var resetButton = document.querySelector("#e-reset");
  resetButton.addEventListener("click", function(){
    popup.classList.remove("elementToFadeInAndOut");
    popup.style.display = "none";
    
    setTimeout(function(){
      popup.classList.add("elementToFadeInAndOut");
      popup.style.display = "grid";
    }, 15);

    // Wait until the animation is over and then remove the class, so that
    // the next click can re-add it.
    setTimeout(function(){
      popup.classList.remove("elementToFadeInAndOut");
      popup.style.display = "none";
    }, 4000);
  });